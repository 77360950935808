






































import { Component, Vue } from "vue-property-decorator";
import AppAlert from "@/components/core/AppAlert.vue";
import AppButton from "@/components/core/AppButton.vue";
import TheVerticalCenter from "@/components/TheVerticalCenter.vue";
import {
  isVerified,
  syncUserProfile,
  sendVerificationEmail,
  verifyEmail,
} from "@/utils/authentication";
import { createErrorNotification, Notification } from "@/utils/notification";
import { BadRequestException } from "@/utils/exception";

@Component({ components: { AppAlert, AppButton, TheVerticalCenter } })
export default class VerifyEmail extends Vue {
  get token(): string | undefined {
    return this.$route.params.token;
  }

  notification: Notification | null = null;
  saving = false;

  async mounted() {
    if (this.token) await this.verifyEmail();
  }

  async verifyEmail(): Promise<void> {
    this.saving = true;
    try {
      if (this.token) await verifyEmail(this.token);
      await this.emailIsVerified();
      await this.redirect();
    } catch (err) {
      this.notification = createErrorNotification(
        "We weren't able to verify your email address",
        "Request another verification email below."
      );
    } finally {
      this.saving = false;
    }
  }

  async resendVerificationEmail(): Promise<void> {
    try {
      this.notification = null;
      this.saving = true;
      await sendVerificationEmail();
    } catch (err) {
      this.notification = createErrorNotification(
        "We couldn't send a verification email",
        err.message
      );
    } finally {
      this.saving = false;
    }
  }

  async emailIsVerified(): Promise<void> {
    await syncUserProfile();
    const verified = await isVerified();
    if (!verified) {
      throw new BadRequestException("The email address has not been verified.");
    }
  }

  async redirect(): Promise<void> {
    await this.$router.push({ name: "LOGIN" });
  }
}
